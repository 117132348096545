.navbar__hamburger,
.navbar__mobile {
     display: none;
}

.navbar__div {
     position: relative;
     /* top: 0px; */
     background-color: #fff;
     color: #000;
     display: flex;
     padding: 20px 7rem;
     align-items: center;
     align-content: space-around;
     max-height: 121px;
     z-index: 999;
}
.contactBtn {
     background-color: #4d52d9;
     padding: 16px 32px 16px 32px;
     border-radius: 10px 10px 10px 10px;
}
.navbar__div__logo img {
     height: 30px;
}

.navbar__div__links {
     flex: 1;
     display: flex;
     align-items: center;
     justify-content: space-around;
}
/* .navbar__div__links ul li a:hover{
  color: #1764d8;

} */

/* .navbar__div__links_ul li a {
  position: relative;
  other styles...
} */

/* .navbar__div__links_ul li a:before {
    content: "";
    position: absolute;
    right: 0px;
    bottom: 0px;
    border-radius: 10px;
    width: 10px;
    height: 5px;
    background: #54CDFA !important;
    visibility: hidden;
    transition: all 0.3s;
    opacity: 0;
} */

/* .navbar__div__links_ul li a:hover::before {
  visibility: visible;
  opacity: 1;
  Add other styles as needed for the hover state
} */

.navbar__div__links_ul li a:hover {
     color: #4d52d9 !important;
}

.navbar__div__links_ul {
     display: flex;
     align-items: center;
     list-style: none;
     margin-left: 130px;
     margin-bottom: 0px;
}
.navbar__div__link {
     margin-left: 50px;
     font-size: 16px;
     line-height: 20px;
     font-weight: 500;
     font-family: "Poppins";
     text-decoration: none;
     color: #000;
}
.navbar__div__link a {
     text-decoration: none;
     color: #000;
     font-size: 16px;
     font-weight: 600;
     font-family: sans-serif;
}

.nav_barlink_dropdown {
     position: relative;
     text-decoration: none;
     color: #000;
}

.navbar__div__link_dropdown_icon {
     position: absolute;
     left: 60px;
}

.navbar__div__link_dropdown_iconMob {
     position: absolute;
     text-align: center;
}
.navbar__div__link_dropdown_iconMob1 {
     position: absolute;
     margin-left: -24px;
     color: #000;
}

.navbar__div__link_dropdown_icon23 {
     position: absolute;

     line-height: 25px;
     .navbar__hamburger,
     .navbar__mobile {
          display: none;
     }

     .navbar__div {
          position: relative;
          /* top: 0px; */
          background-color: #fff;
          color: #000;
          display: flex;
          padding: 20px 7rem;
          align-items: center;
          align-content: space-around;
          max-height: 121px;
          z-index: 999;
     }
     .contactBtn {
          background-color: #4d52d9;
          padding: 16px 32px 16px 32px;
          border-radius: 10px 10px 10px 10px;
     }
     .navbar__div__logo img {
          height: 60px;
          scale: 2;
     }

     .navbar__div__links {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-around;
     }
     /* .navbar__div__links ul li a:hover{
    color: #1764d8;

  } */

     /* .navbar__div__links_ul li a {
    position: relative;
    other styles...
  } */

     /* .navbar__div__links_ul li a:before {
      content: "";
      position: absolute;
      right: 0px;
      bottom: 0px;
      border-radius: 10px;
      width: 10px;
      height: 5px;
      background: #54CDFA !important;
      visibility: hidden;
      transition: all 0.3s;
      opacity: 0;
  } */

     /* .navbar__div__links_ul li a:hover::before {
    visibility: visible;
    opacity: 1;
    Add other styles as needed for the hover state
  } */

     .navbar__div__links_ul li a:hover {
          color: #4d52d9 !important;
     }

     .navbar__div__links_ul {
          display: flex;
          align-items: center;
          list-style: none;
          margin-left: 130px;
          margin-bottom: 0px;
     }
     .navbar__div__link {
          margin-left: 50px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          font-family: "Poppins";
          text-decoration: none;
          color: #000;
     }
     .navbar__div__link a {
          text-decoration: none;
          color: #000;
          font-size: 16px;
          font-weight: 600;
          font-family: sans-serif;
     }

     .nav_barlink_dropdown {
          position: relative;
          text-decoration: none;
          color: #000;
     }

     .navbar__div__link_dropdown_icon {
          position: absolute;
          left: 60px;
     }

     .navbar__div__link_dropdown_iconMob {
          position: absolute;
          text-align: center;
     }
     .navbar__div__link_dropdown_iconMob1 {
          position: absolute;
          margin-left: -24px;
          color: #000;
     }

     .navbar__div__link_dropdown_icon23 {
          position: absolute;

          line-height: 25px;
          margin-left: 45px;
     }

     .btnREGION {
          padding: 8px;
          padding-right: 70px;
          padding-left: 15px;
          border-radius: 23px;
          width: 130px;
          /* width: 150px; */
          font-size: 20px;
          font-weight: 700;
          background: #f5f5f6;
          border-radius: 30px;
          font-size: 14px;
     }

     .btnREGIONuk {
          padding: 7px;
          border-radius: 50px;
          width: 130px;
          margin-left: -30px;
          text-align: left;
          font-size: 20px;
          font-weight: 500;
     }

     .product_text ul li a:hover {
          color: #4d52d9 !important;
     }

     .product_text ul li a {
          font-family: "Poppins";
          font-size: 18px;
          line-height: 30px;
          text-decoration: none;
          color: #000;
     }
     .product_text {
          color: #fff;
          text-decoration: none;
     }

     .product_text2 ul li a:hover {
          color: #4d52d9 !important;
     }

     .navbar__div__links_buttons button {
          background: transparent;
          color: #ffffff;
          margin: 0 10px;
          border: 0px;
          cursor: pointer;
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
     }

     .navbar__div__links_buttons button:last-child {
          background-color: #fff;
          color: #1764d8;
          padding: 10px 40px;
          border-radius: 20px;
     }
     .navbar__div__link__dropdown_div {
          position: absolute;
          display: flex;
          background-color: #fff;
          padding: 1rem 2rem;
          left: 0;
          /* width: 100%; */
          top: 54px;
          box-shadow: 0px 0px 3px 0 #00000059;
          z-index: 99999;
          color: #000;
     }

     .navbar__div__link__dropdown_div__items {
          display: flex;
     }
     .navbar__div__link__dropdown_div__items > ul {
          list-style: none;
     }
     .navbar__div__link__dropdown_div__items ul li {
          width: auto;
     }

     .productDropdown {
          position: absolute;
          top: 65px;
          /* left: 0px; */
          /* width: 100%; */
          background-color: #fff;
          border-radius: 10px;
     }

     .productDropUp12 {
          position: absolute;
          top: 72px;
          /* left: 0px; */
          /* width: 100%; */
          background-color: #ccc;
          border-radius: 10px;
     }

     .navbar_backdrop {
          position: absolute;
          top: 100px;
          left: 0px;
          padding: 100px;
          width: 100%;
          background-color: #f5f5f7;
          height: 100vh;
          opacity: -0;
     }

     .single_product_desc {
          display: flex;
          color: #000;
     }

     .product_icon {
          padding: 10px 20px;
          font-size: 40px;
     }

     .product_text {
          color: #fff !important;
     }
     .product_text2 {
          color: #000000 !important;
     }
     .product_text h4 {
          color: #7b7b7b !important;
     }

     @media screen and (max-width: 768px) {
          .navbar__div {
               position: sticky;
               top: 0px;
               background-color: #fff;
               color: #000;
               display: flex;
               padding: 30px;
               align-items: center;
               align-content: space-around;
               max-height: 121px;
               z-index: 999;
          }
          .navbar__div__logo img {
               height: 40px;
          }
          .navbar__mobile {
               background: #26a8ff;
               display: block;
               height: 0;
               left: 0;
               overflow: hidden;
               padding: 0 4rem;
               position: absolute;
               top: 75px !important;
               transition: 0.5s ease;
               width: 100%;
               z-index: 9999;
               color: #fff;
          }
     }

     @media screen and (max-width: 1304px) {
          .navbar__hamburger {
               display: block;
               margin-left: auto;
               font-size: 20px;
          }
          .navbar__div__links {
               display: none;
          }
          .navbar__mobile {
               background: #26a8ff;
               display: block;
               height: 0;
               left: 0;
               overflow: hidden;
               padding: 0 4rem;
               position: absolute;
               top: 80px;
               transition: 0.5s ease;
               width: 100%;
               z-index: 9999;
               color: #fff;
          }

          .navbar__mobile ul {
               list-style: none;
          }
          .navbar__div ul li {
               margin: 20px 0;
          }
     }

     .borderRadiusNavbarDropdown {
          line-height: 40px;
          width: 255px;
          font-weight: 600;
          font-size: 18px;
          margin-top: 27px;
          margin-left: -26px;
     }

     .borderRadiusNavbarDropdownUp {
          /* border-radius: 0 20px 20px 0; */
          /* padding: 41px; */
          /* padding-top: 20px; */
          padding-right: 40px;
          padding-left: 30px;
          /* padding-bottom: 32px; */
          line-height: 40px;
          width: 130px;
          font-weight: 600;
          font-size: 18px;
     }

     .slide_from_top {
          -webkit-animation: slide_from_topAn 2s ease-in-out;
          animation: slide_from_topAn 2s ease-in-out;
          display: block;
          height: auto !important;
     }
}

.btnREGION {
     padding: 8px;
     padding-right: 70px;
     padding-left: 15px;
     border-radius: 23px;
     width: 130px;
     /* width: 150px; */
     font-size: 20px;
     font-weight: 700;
     background: #f5f5f6;
     border-radius: 30px;
     font-size: 14px;
}

.btnREGIONuk {
     padding: 7px;
     border-radius: 50px;
     width: 130px;
     margin-left: -30px;
     text-align: left;
     font-size: 20px;
     font-weight: 500;
}

.product_text ul li a:hover {
     color: #4d52d9 !important;
}

.product_text ul li a {
     font-family: "Poppins";
     font-size: 18px;
     line-height: 30px;
     text-decoration: none;
     color: #000;
}
.product_text {
     color: #fff;
     text-decoration: none;
}

.product_text2 ul li a:hover {
     color: #4d52d9 !important;
}

.navbar__div__links_buttons button {
     background: transparent;
     color: #ffffff;
     margin: 0 10px;
     border: 0px;
     cursor: pointer;
     font-family: Inter;
     font-size: 16px;
     font-weight: 600;
     line-height: 19px;
     letter-spacing: 0em;
}

.navbar__div__links_buttons button:last-child {
     background-color: #fff;
     color: #1764d8;
     padding: 10px 40px;
     border-radius: 20px;
}
.navbar__div__link__dropdown_div {
     position: absolute;
     display: flex;
     background-color: #fff;
     padding: 1rem 2rem;
     left: 0;
     /* width: 100%; */
     top: 54px;
     box-shadow: 0px 0px 3px 0 #00000059;
     z-index: 99999;
     color: #000;
}

.navbar__div__link__dropdown_div__items {
     display: flex;
}
.navbar__div__link__dropdown_div__items > ul {
     list-style: none;
}
.navbar__div__link__dropdown_div__items ul li {
     width: auto;
}

.productDropdown {
     position: absolute;
     top: 65px;
     left: 100px;
     /* height: 80vh; */
     border: 0.5px solid gray;
     overflow-y: scroll;
     width: 90% !important;
     background-color: #fff;
     border-radius: 10px;
}
@media only screen and (max-width: 600px) {
     .productDropdown {
          z-index: 100;
          background-color: #fff;
          left: 50px;
          top: 80px;
     }
     .product_text {
          margin-top: 10px;
          border-bottom: 1px solid gray;
     }
}

.productDropUp12 {
     position: absolute;
     top: 72px;
     /* left: 0px; */
     /* width: 100%; */
     background-color: #ccc;
     border-radius: 10px;
}

.navbar_backdrop {
     position: absolute;
     top: 100px;
     left: 0px;
     padding: 100px;
     width: 100%;
     background-color: #f5f5f7;
     height: 100vh;
     opacity: -0;
}

.single_product_desc {
     display: flex;
     color: #000;
}

.product_icon {
     padding: 10px 20px;
     font-size: 40px;
}

.product_text {
     /* color: #fff !important; */
     flex: 1;
}
.product_text2 {
     color: #000000 !important;
}
.product_text h4 {
     color: #7b7b7b !important;
}

@media screen and (max-width: 768px) {
     .navbar__div {
          position: sticky;
          top: 0px;
          background-color: #fff;
          color: #000;
          display: flex;
          padding: 30px;
          align-items: center;
          align-content: space-around;
          max-height: 121px;
          z-index: 999;
     }
     .navbar__div__logo img {
          height: 40px;
     }
     .navbar__mobile {
          background: #26a8ff;
          display: block;
          height: 0;
          left: 0;
          overflow: hidden;
          padding: 0 4rem;
          position: absolute;
          top: 75px !important;
          transition: 0.5s ease;
          width: 100%;
          z-index: 9999;
          color: #fff;
     }
}

@media screen and (max-width: 1304px) {
     .navbar__hamburger {
          display: block;
          margin-left: auto;
          font-size: 20px;
     }
     .navbar__div__links {
          display: none;
     }
     .navbar__mobile {
          background: #fff;
          display: block;
          height: 0;
          left: 0;
          overflow: hidden;
          padding: 0 4rem;
          position: absolute;
          top: 80px;
          transition: 0.5s ease;
          width: 100%;
          z-index: 9999;
          color: #000;
     }

     .navbar__mobile ul {
          list-style: none;
     }
     .navbar__div ul li {
          margin: 20px 0;
     }
}

.borderRadiusNavbarDropdown {
     line-height: 40px;
     width: 255px;
     font-weight: 600;
     font-size: 18px;
     margin-top: 27px;
     padding: 10px;
     margin-left: -26px;
}

.borderRadiusNavbarDropdownUp {
     /* border-radius: 0 20px 20px 0; */
     /* padding: 41px; */
     /* padding-top: 20px; */
     padding-right: 40px;
     padding-left: 30px;
     /* padding-bottom: 32px; */
     line-height: 40px;
     width: 130px;
     font-weight: 600;
     font-size: 18px;
}

.slide_from_top {
     -webkit-animation: slide_from_topAn 2s ease-in-out;
     animation: slide_from_topAn 2s ease-in-out;
     display: block;
     height: auto !important;
}
