  .request_demo {
    background: #1764d8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 66px 0;
    text-align: center;
  }
  .request_demo_text {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
    margin-bottom: 30px;
  }

  .footer_top__text {
    font-weight: 400;
    font-size: 15.8126px;
    line-height: 21px;
    /* or 133% */
    text-align: center;
    margin: 0 auto;
    color: #7b7b7b;
    max-width: 372px;
  }

  .main_footer {
    padding: 1px 30px;
    background-color: #fff;
  }

  .btn_footer {
    background: #1764d8;
    border: 1px solid #1764d8;
    border-radius: 6.32504px;
    height: 54.82px;
    color: #fff;
    padding: 15px 20px;
  }
  .btn_footer:hover{
    color: #fff;
  }

  .footer_links {
    display: flex;
    margin: auto 80px;

  }

  .footer_links div {
    flex-grow: 1;
    /* flex-basis: 0; */
  }

  .copyright {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    padding: 10px 105px;
    color: #fff;
    background-color: #000;
  }

  .copyright div {
    flex-grow: 1;
    flex-basis: 0;
  }

  .footer_icons {
    font-size: 25px;
  }

  .footer_heading {
    font-size: 19px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
  line-height: 30px;

  }
.footer_ul li a:hover{
  /* background-color: #1764d8; */
  /* text-decoration: underline; */
  color: #4D52D9;
}
.footer_ul li a{
  /* background-color: #1764d8; */
  text-decoration: none;
  color: #6C6C6C;
      font-size: 16px;
    font-family: sans-serif;
    font-weight: 400;
    line-height: 1.75em;
}

  .footer_ul {
    list-style: none;
    color: #fff !important;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    font-size: 16px;
    line-height: 26px;
    padding: 0px;
  }

  @media (max-width: 1111px) {
    .footer_links {
      display: flex;
      /* margin: auto 50px; */
      margin-left: 10px;
      margin-top: 100px;
    }
    .footer_heading{
      display: block !important;
      padding: 0px !important;
    }
  }

  @media (max-width: 768px) {
    .footer_links,
    .copyright {
      flex-direction: column;
    }
    .copyright {
      padding: 30px;
      text-align: center;
    }
    .footer_icons {
      margin-top: 20px;
    }
    .main_footer {
      padding: 0px;
  }
  .footer_heading{
    display: block !important;
  }
  }

  @media screen and (min-width: 1600px) {
    
  .footer_links {
    display: flex;
    /* margin: auto;
    margin-top: 150px;
    margin-bottom: 10px; */
  }
  }